
import { apiGet } from "@/api";
import { Toast } from "@/utils/notify";
import { defineComponent, inject, ref } from "vue";

export default defineComponent({
  name: "SellGiftCardTransactionRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  emits: ["open-trade"],
  setup(props, ctx) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);
    const admin = ref<{ username: string } | null>(null);

    // const maybeGotoDetails = (id: string) => {
    //   if (props.dt.is_processing && props.dt.processed_by) {
    //     getAdminDetails(props.dt.processed_by);
    //   } else {
    //     router.push(`/cards/sell-t/${id}`);
    //   }
    // };

    const getAdminDetails = async (id: string) => {
      loading.value = true;
      try {
        const response = await apiGet(`/user-account/${id}`);
        admin.value = response.data.data.user as { username: string };
        Toast.fire({
          icon: "success",
          title: `This transaction is being processed by ${admin.value?.username}`,
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    // const openTrade = () => {
    //   RequestConfirmation({
    //     title: "Open Trade",
    //     message: "Are you sure you want to open this trade?",
    //     onConfirm: () => {
    //       ctx.emit("open-trade", props.dt.id);
    //     },
    //   });
    // };

    return {
      loading,
      RequestConfirmation,
      // maybeGotoDetails,
      getAdminDetails,
    };
  },
});
