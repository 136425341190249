
import { inject, ref, defineComponent, onBeforeMount } from "vue";
import { apiGet } from "@/api";
import { Toast } from "@/utils/notify";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import EachUserWallet from "@/views/users/constituent/EachUserWallet.vue";

export default defineComponent({
  name: "UserWallets",
  components: { EachUserWallet, DualRingLoader },
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);
    const data = ref(null);

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/user-account/${props.dt.id}/fetch-wallets`
        );
        data.value = response.data.data.fiatWallets;
        Toast.fire({
          icon: "success",
          title: "Wallet Details Fetched Successfully",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      loading,
      data,
      RequestConfirmation,
      RefreshData,
    };
  },
});
