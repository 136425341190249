import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center py-3 text-muted"
}
const _hoisted_2 = { class: "row justify-content-center gy-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EachUserWallet = _resolveComponent("EachUserWallet")!
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!

  return (_openBlock(), _createBlock(_component_DualRingLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      (_ctx.data?.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "No Transaction"))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (dt, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "col-12 col-lg-6"
          }, [
            _createVNode(_component_EachUserWallet, {
              dt: dt,
              onRefresh: _ctx.RefreshData
            }, null, 8, ["dt", "onRefresh"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}