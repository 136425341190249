
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import Pagination from "@/components/mini/Pagination.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import SellGiftCardTransactionRow from "@/views/cards/constituent/SellGiftCardTransactionRow.vue";
import DataFilterSelector from "@/components/selector/DataFilterSelector.vue";

export default defineComponent({
  name: "UserSellCardTransactions",
  components: {
    DataFilterSelector,
    SellGiftCardTransactionRow,
    HourglassLoader,
    Pagination,
    CountSelector,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const data = ref(null);
    const search = ref("");

    const route = useRoute();

    const filters = {
      ALL: "ALL",
      SUBMITTED: "Submitted/New",
      CANCELED: "Canceled",
      PROCESSING: "Processing",
      DONE: "Done/Success",
      FAILED: "Done/Failed",
    };
    const status = ref("ALL");

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/cards/sell/transactions/${props.user.id}
`,
          Object.assign((data.value as any)?.meta ?? {}, {
            status: status.value === "ALL" ? undefined : status.value,
            search: search.value ? search.value : undefined,
          })
        );
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      route,
      data,
      loading,
      filters,
      status,
      search,
    };
  },
});
