
import { ref, defineComponent, onBeforeMount } from "vue";
import { apiGet } from "@/api";
import { Toast } from "@/utils/notify";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";

export default defineComponent({
  name: "UserKyc",
  components: { DualRingLoader },
  props: {
    dt: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  // emits: ["bank-details"],
  setup(props) {
    const loading = ref(false);
    const data = ref(null);

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/kyc/user/${props.user.id}`);
        data.value = response.data.data.userkyc;

        console.log(data.value);

        // ctx.emit("bank-details", data.value);

        Toast.fire({
          icon: "success",
          title: "User kyc Fetched Successfully",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      loading,
      data,
      RefreshData,
    };
  },
});
