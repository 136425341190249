import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "text-info" }
const _hoisted_5 = { class: "text-center text-success" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = { class: "card border-light p-4" }
const _hoisted_8 = { class: "card-header border-0 text-center pb-0" }
const _hoisted_9 = { class: "h4" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "form-group mb-4" }
const _hoisted_12 = { class: "input-group-text" }
const _hoisted_13 = { class: "d-flex justify-content-center align-items-center mt-4" }
const _hoisted_14 = { class: "font-weight-normal" }
const _hoisted_15 = {
  ref: "closeButton",
  class: "font-weight-bold",
  "data-bs-dismiss": "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!
  const _component_DefaultModal = _resolveComponent("DefaultModal")!

  return (_openBlock(), _createBlock(_component_DualRingLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.dt.Currency.name) + " Wallet", 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "" }, "Balance", -1)),
            _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.dt.Currency.code) + " " + _toDisplayString(_ctx.dt.balance), 1)
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-footer text-center" }, [
          _createElementVNode("button", {
            class: "btn btn-sm btn-primary",
            "data-bs-target": "#credit-debit-wallet",
            "data-bs-toggle": "modal"
          }, " Credit/Debit Wallet ")
        ], -1)),
        _createVNode(_component_DefaultModal, { id: "credit-debit-wallet" }, {
          default: _withCtx(() => [
            _createVNode(_component_DualRingLoader, { loading: _ctx.loading }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("h2", _hoisted_9, " Enter the amount you want debit/credit for " + _toDisplayString(_ctx.dt.Currency.code) + " wallet belonging to user #" + _toDisplayString(_ctx.dt.user_id), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("form", {
                      onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                  _ctx.RequestConfirmation(
                    'Are you sure to proceed? This action is irreversible',
                    _ctx.Submit
                  )
                ), ["prevent"])),
                      class: "mt-4"
                    }, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "alert text-white alert-dark" }, " Please use a negative value to debit wallet ", -1)),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_InputWrapper, {
                          errors: _ctx.formError.amount
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.dt.Currency.code), 1),
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control form-control-lg px-1",
                              required: "",
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.amount) = $event)),
                              placeholder: "Amount"
                            }, null, 512), [
                              [
                                _vModelText,
                                _ctx.formData.amount,
                                void 0,
                                { number: true }
                              ]
                            ])
                          ]),
                          _: 1
                        }, 8, ["errors"])
                      ]),
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-center" }, [
                        _createElementVNode("button", {
                          type: "submit",
                          class: "btn btn-block btn-dark"
                        }, " Proceed ")
                      ], -1))
                    ], 32),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, [
                        _createElementVNode("a", _hoisted_15, "Cancel", 512)
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["loading"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}