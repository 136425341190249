
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import {
  onBeforeMount,
  ref,
  defineComponent,
  inject,
  watch,
  onMounted,
} from "vue";
import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";
import UserWallets from "@/views/users/constituent/UserWallets.vue";
import UserBankAccounts from "@/views/users/constituent/UserBankAccounts.vue";
import UserSellCryptoTransactions from "./constituent/UserSellCryptoTransactions.vue";
import UserSellCardTransactions from "./constituent/UserSellCardTransactions.vue";
import UserCardWithdrawals from "./constituent/UserCardWithdrawals.vue";
import UserCryptoWithdrawals from "./constituent/UserCryptoWithdrawals.vue";
import UserKyc from "./constituent/UserKyc.vue";

export default defineComponent({
  name: "UserDetail",
  components: {
    UserWallets,
    UserBankAccounts,
    HourglassLoader,
    UserSellCryptoTransactions,
    UserSellCardTransactions,
    UserCardWithdrawals,
    UserCryptoWithdrawals,
    UserKyc,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const screenWidth = ref(window.innerWidth);
    const showMobileMenu = ref(false);

    const userBalance = ref(null);

    const currentTab = ref("profile");

    const selectTab = (tab: string) => {
      currentTab.value = tab;
    };

    const RequestConfirmation = inject("RequestConfirmation");

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/user-account/${route.params.userId}`);
        data.value = response.data.data.user;
        getUserBalance();
        Toast.fire({
          icon: "success",
          title: "User Detail Fetched Successfully",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const getUserBalance = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/wallets/${route.params.userId}`);
        userBalance.value = response.data.data.fiatWallets;
        // Toast.fire({
        //   icon: "success",
        //   title: "User Detail Fetched Successfully",
        // }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    watch(screenWidth, () => {
      console.log("Screen width changed!");
    });

    onMounted(() => {
      window.addEventListener("resize", () => {
        screenWidth.value = window.innerWidth;
      });
    });

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      getUserBalance,
      RequestConfirmation,
      selectTab,
      screenWidth,
      showMobileMenu,
      currentTab,
      userBalance,
      route,
      data,
      loading,
    };
  },
});
