import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row justify-content-around gy-4"
}
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "fw-bolder text-info" }
const _hoisted_4 = { class: "text-center col-12 col-md-6 col-lg-4" }
const _hoisted_5 = { class: "fw-bolder text-info" }
const _hoisted_6 = { class: "text-center col-12 col-md-6 col-lg-4" }
const _hoisted_7 = {
  key: 0,
  class: "fw-bolder text-info"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "text-center col-12 col-md-6 col-lg-4" }
const _hoisted_10 = {
  key: 0,
  class: "fw-bolder text-info"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "my-1" }
const _hoisted_13 = { class: "my-1" }
const _hoisted_14 = { class: "my-1" }
const _hoisted_15 = { class: "my-1" }
const _hoisted_16 = { class: "text-center col-12 col-md-6 col-lg-4" }
const _hoisted_17 = {
  key: 0,
  class: "fw-bolder text-info"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "text-center col-12 col-md-6" }
const _hoisted_20 = {
  key: 0,
  class: "fw-bolder text-info"
}
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "text-center col-12 col-md-6" }
const _hoisted_23 = {
  key: 0,
  class: "fw-bolder text-info"
}
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "text-center" }
const _hoisted_26 = {
  key: 0,
  class: "fw-bolder text-info"
}
const _hoisted_27 = {
  key: 1,
  class: "row"
}
const _hoisted_28 = { class: "col-12" }
const _hoisted_29 = { class: "card card-body border-light shadow-sm" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!

  return (_openBlock(), _createBlock(_component_DualRingLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      (_ctx.data)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "" }, "KYC Level", -1)),
              _createElementVNode("h5", _hoisted_3, "Level " + _toDisplayString(_ctx.data?.kyc_setting_id), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "" }, "Email", -1)),
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.dt.email), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "" }, "BVN", -1)),
              _createElementVNode("div", null, [
                (!_ctx.data?.bvn)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_7, _cache[2] || (_cache[2] = [
                      _createElementVNode("span", null, "None", -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.data?.bvn), 1))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "" }, "BVN Details", -1)),
              _createElementVNode("div", null, [
                (!_ctx.data?.bvn_details)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_10, _cache[4] || (_cache[4] = [
                      _createElementVNode("span", null, "None", -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _cache[5] || (_cache[5] = _createElementVNode("h6", { class: "fw-bolder text-info" }, "First Name", -1)),
                        _createElementVNode("div", null, _toDisplayString(_ctx.data?.bvn_details.firstName), 1)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _cache[6] || (_cache[6] = _createElementVNode("h6", { class: "fw-bolder text-info" }, "Last Name", -1)),
                        _createElementVNode("div", null, _toDisplayString(_ctx.data?.bvn_details.lastName), 1)
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _cache[7] || (_cache[7] = _createElementVNode("h6", { class: "fw-bolder text-info" }, "Date of Birth", -1)),
                        _createElementVNode("div", null, _toDisplayString(_ctx.data?.bvn_details.dob), 1)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _cache[8] || (_cache[8] = _createElementVNode("h6", { class: "fw-bolder text-info" }, "Country Code", -1)),
                        _createElementVNode("div", null, _toDisplayString(_ctx.data?.bvn_details.countryCode), 1)
                      ])
                    ]))
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "" }, "ADDRESS", -1)),
              _createElementVNode("div", null, [
                (!_ctx.data?.address)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_17, _cache[10] || (_cache[10] = [
                      _createElementVNode("span", null, "No Address", -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.data?.address), 1))
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "" }, "ID Number", -1)),
              _createElementVNode("div", null, [
                (!_ctx.data?.id_number)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_20, _cache[12] || (_cache[12] = [
                      _createElementVNode("span", null, "No ID number", -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.data?.id_number), 1))
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "" }, "ID type", -1)),
              _createElementVNode("div", null, [
                (!_ctx.data?.id_type)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_23, _cache[14] || (_cache[14] = [
                      _createElementVNode("span", null, "No type", -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.data?.id_type), 1))
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _cache[17] || (_cache[17] = _createElementVNode("h4", { class: "" }, "ID", -1)),
              _createElementVNode("div", null, [
                (!_ctx.data?.id_image_urls)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_26, _cache[16] || (_cache[16] = [
                      _createElementVNode("span", null, "No ID Images", -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("div", _hoisted_30, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data?.id_image_urls, (image, index) => {
                              return (_openBlock(), _createElementBlock("img", {
                                class: "col-12 col-md-6 g-2 g-lg-3",
                                src: image,
                                key: index,
                                alt: "Img"
                              }, null, 8, _hoisted_31))
                            }), 128))
                          ])
                        ])
                      ])
                    ]))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}