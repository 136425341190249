import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center py-3 text-muted"
}
const _hoisted_2 = { class: "row justify-content-around gy-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "fw-bolder text-info" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "fw-bolder text-info" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "fw-bolder text-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!

  return (_openBlock(), _createBlock(_component_DualRingLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      (_ctx.data?.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "No Transaction"))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (dt, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "col-12 col-lg-5 card p-2"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "" }, "Bank Name", -1)),
                _createElementVNode("h5", _hoisted_5, _toDisplayString(dt.details.bank_name), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "" }, "Account Name", -1)),
                _createElementVNode("h5", _hoisted_7, _toDisplayString(dt.details.account_name), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "" }, "Account Number", -1)),
                _createElementVNode("h5", _hoisted_9, _toDisplayString(dt.account_number), 1)
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}